import React from 'react';
import Icon from '../Icon';
import Section from '../Section';
import { Container, Row, Column } from '../Grid';
import Image from '../Image';
import './styles.scss';

const SurveyCallout = () => {
  return (
    <Section className="pb-4 pt-4">
      <Container fullWidth>
        <Row>
          <Column
            largeOffset={1}
            large={10}
            className="surveyCallout__feedback-callout-column pl-0 pr-0"
          >
            <a
              href="https://surveys.discoveryeducation.com/s3/Discover-Data-Program-Survey"
              target="_blank"
              rel="noreferrer"
              className="surveyCallout__link"
            >
              <div className="surveyCallout__feedback-callout-wrapper">
                {/* <div style={{ width: '30%' }}>
                  <Image filename="promo-ed-survey@2x.jpg" />
                </div> */}
                <div className="surveyCallout__feedback-callout-text">
                  Share your insights in our educator survey. Your feedback is the valuable data.
                </div>
                <div className="surveyCallout__feedback-callout-icon-container">
                <Image className="callout__icon" filename="icon-arrow.svg"/>
                </div>
              </div>
            </a>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default SurveyCallout;
