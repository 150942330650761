import classNames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';

import './styles.scss';

const Action = ({
  dropdown,
  disabled,
  label,
  metadata,
  module,
  moduleName,
  link,
  download,
  noIcon,
  asLink,
  index,
  actions,
  pptx,
  googleSlides,
  segmentData,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const handleDropdownClick = () => setDropdownOpen((state) => !state);
  const handleSegmentClick = (e) => {
    let anchorTarget = e.target;
    let clickType = e.target.dataset.clickType;
    if (!clickType) {
      anchorTarget = anchorTarget.closest('a');
    }
    if (segmentData.clickType === 'download') {
      window.analytics.track('File Downloaded', {
        label: segmentData.clickLabel,
        extension: segmentData.format,
        filename: `${segmentData.clickLabel}.${segmentData.format}`,
        url: anchorTarget.href,
        target: anchorTarget.target,
      });
    }
  };

  const renderDropdown = () => {
    return (
      <>
        <Button
          onClick={handleDropdownClick}
          className={classNames(
            'resource__dropdown-toggle',
            dropdownOpen && 'resource__dropdown-toggle--open'
          )}
        >
          <Icon
            name="caratdown"
            className={classNames(
              'resource__dropdown-carat',
              dropdownOpen && 'resource__dropdown-carat--open'
            )}
          />
          {label}
        </Button>
        {dropdownOpen && (
          <div className="resource__dropdown-items">
            {actions.map((action, i) => (
              <Action {...action} key={i} />
            ))}
          </div>
        )}
      </>
    );
  };

  const renderModuleLink = () => {
    return (
      <Button
        to={module}
        target="_blank"
        forceExternal
        data-click-type="Module Launch"
        data-click-name={moduleName}
        className={classNames(
          'resource__action-link',
          disabled && 'resource__action-link--disabled'
        )}
      >
        {!noIcon && (
          <Icon
            marginRight
            name="externallink"
            className="resource__action-icon"
          />
        )}
        {label}
      </Button>
    );
  };

  const renderLink = () => {
    return asLink ? (
      <Link
        to={link}
        className={classNames(
          'resource__action-link',
          disabled && 'resource__action-link--disabled'
        )}
      >
        {!noIcon && (
          <Icon marginRight name="module" className="resource__action-icon" />
        )}
        {label}
      </Link>
    ) : (
      <Button to={link} className="resource__action-link">
        {label}
      </Button>
    );
  };

  const renderPptxGoogleSlides = () => {
    return (
      <Button
        className={classNames('resource__action-googleSlides')}
        link={asLink}
        isSecondary={index}
      >
        <div>
          {!noIcon && (
            <Icon
              name="externallink"
              className="resource__action-icon"
              marginRight
            />
          )}
          {label}
        </div>
        <div className="resource__action-icon-container">
          {pptx && (
            <a
              href={pptx}
              target="_blank"
              rel="noopener noreferrer"
              data-cep-ignore-click
              onClick={(e) => handleSegmentClick(e)}
            >
              <Icon
                name="pptxdownload"
                className="resource__action-icon-download"
                marginLeft
              />
            </a>
          )}
          {googleSlides && (
            <a
              href={googleSlides}
              target="_blank"
              rel="noopener noreferrer"
              data-cep-ignore-click
              onClick={(e) => handleSegmentClick(e)}
            >
              <Icon
                name="googleslidesdownload"
                className="resource__action-icon-download"
                marginLeft
              />
            </a>
          )}
        </div>
      </Button>
    );
  };

  const renderDownload = () => {
    return (
      <Button
        to={download}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          'resource__action-link',
          disabled && 'resource__action-link--disabled'
        )}
        link={asLink}
        isSecondary={index}
        {...(segmentData && {
          'data-cep-ignore-click': true,
          onClick: (e) => handleSegmentClick(e),
        })}
      >
        {!noIcon && (
          <Icon
            name="externallink"
            className="resource__action-icon"
            marginRight
          />
        )}
        {label}
      </Button>
    );
  };

  return (
    <div className="resource__action">
      {dropdown && renderDropdown()}
      {link && renderLink()}
      {module && renderModuleLink()}
      {(pptx || googleSlides) && renderPptxGoogleSlides()}
      {download && renderDownload()}
      {/* {download && renderDownload()} */}
      {metadata && <span className="resource__metadata">{metadata}</span>}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
  /** Renders a dropdown */
  dropdown: PropTypes.bool,
  /** Disables link */
  disabled: PropTypes.bool,
  /** Adds a .PPTX download icon with link */
  pptx: PropTypes.string,
  /** Add a Google Slides icon with link */
  googleSlides: PropTypes.string,

  segmentData: PropTypes.shape({
    /** Format to pass to segment */
    format: PropTypes.string.isRequired,
    /** Label to pass to Segment when clicking the link */
    clickLabel: PropTypes.string.isRequired,
    /** Click type to pass to segment when clicking the link */
    clickType: PropTypes.string.isRequired,
  }),
};

export default Action;
