import React from 'react';
import Layout from '../../components/Layout';
import Callout from '../../components/Callout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Hero from '../../components/Hero';
import Resource from '../../components/Resource';
import Subfooter from '../../components/Subfooter';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import SurveyCallout from '../../components/SurveyCallout';
import { graphql } from 'gatsby';
import { getKeyedResources } from '../../utils/resource-helper';
import Modal from '../../components/Modal';
import ScheduleAVisitForm from '../../components/ScheduleAVisitForm';

const textOverlay = (
  <div className="career-profiles__text_overlay_container">
    <Container fullWidth>
      <div className="career-profiles__text_overlay_info">
        Explore data-driven careers
      </div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    <span className="subfooter__highlighted-text">Data Science</span> is one of
    the twenty fastest growing occupations in the world.
  </span>
);

const CareerProfiles = ({ data }) => {
  const {
    directorDataScience,
    clientSolutions,
    clientSolutionsExecutive,
    dataEngineer,
    clientOperationsManager,
    accountManager,
    dataScientist,
    salesEngineer,
    innovationAnalyst,
  } = getKeyedResources(data);

  return (
    <Layout title="Career-Profiles">
      <Hero
        mediaSource="img-lead-triangle-career-profiles.png"
        textOverlay={textOverlay}
      />
      <Section className="career-profiles__callout-section section--gray">
        <Container fullWidth>
          <Row className="career-profiles__callout-row">
            <Column large={12}>
              <Callout
                className="career-profiles__callout"
                containerClassName="career-profiles__callout-container"
              >
                Meet today’s diverse data pros, inspire tomorrow’s
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 section--gray">
        <Container>
          <Row>
            <Column size={10}>
              <p className="text-sans-serif-large mb-2">
                Careers in data are as limitless as data itself. This field
                employs communicators, creative thinkers, number-crunchers,
                problem solvers, and innovators across all industries and the
                globe.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pt-0 section--gray">
        <Container>
          <Row>
            <Column size={4}>
              <Resource
                title={clientSolutionsExecutive.title}
                className={clientSolutionsExecutive.className}
                description={clientSolutionsExecutive.description}
                img={clientSolutionsExecutive.img}
                actions={clientSolutionsExecutive.actions}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={directorDataScience.title}
                className={directorDataScience.className}
                description={directorDataScience.description}
                img={directorDataScience.img}
                actions={directorDataScience.actions}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={dataEngineer.title}
                className={dataEngineer.className}
                description={dataEngineer.description}
                img={dataEngineer.img}
                actions={dataEngineer.actions}
                linkResource
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                title={clientOperationsManager.title}
                className={clientOperationsManager.className}
                description={clientOperationsManager.description}
                img={clientOperationsManager.img}
                actions={clientOperationsManager.actions}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={clientSolutions.title}
                className={clientSolutions.className}
                description={clientSolutions.description}
                img={clientSolutions.img}
                actions={clientSolutions.actions}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={accountManager.title}
                className={accountManager.className}
                description={accountManager.description}
                img={accountManager.img}
                actions={accountManager.actions}
                linkResource
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container className={'career-profiles--container'}>
          <Row className="mb-4">
            <Column size={4}>
              <Image filename="promo-request-a-visit@2x.jpg" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">
                Virtually connect your class with a data expert
              </h2>
              <p className="text-sans-serif">
                Show students the power of data in action by putting them
                face-to-face with an inspiring real-world data expert, who will
                lead your class through a unique digital lesson.
              </p>
              <Modal
                trigger={
                  <Button>
                    <Icon name="externallink" />
                    <span className="ml-1">Request a visit</span>
                  </Button>
                }
              >
                <ScheduleAVisitForm />
              </Modal>
              <div className="mt-1">
                <Button
                  className={'button-secondary'}
                  target="_"
                  to="/sites/default/files/Curriculum/Nielsen-DiscoverData-VolunteerEdGuide-V2.0.pdf"
                >
                  <Icon name="externallink" />
                  <span className="ml-1">Volunteer visit guide</span>
                </Button>
              </div>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Image filename="promo-career-capture-sheet@2x.jpg" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">Career capture sheet</h2>
              <p className="text-sans-serif">
                This exercise helps students connect with current data careers
                by highlighting skill relevancy and creating opportunities for
                self-reflection and goal-setting.
              </p>
              <Button
                to="/sites/default/files/documents/NF-DiscoverData-X9Adaptations-CareerCaptureSheet.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="mb-1"
              >
                <Icon name="externallink" />
                <span className="pl-1">Career exploration activity</span>
              </Button>
              <div>
                <Button
                  to="/sites/default/files/Curriculum/NF-DiscoverData-X9Adaptations-CareerCaptureSheet-espanol.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  isSecondary={true}
                >
                  <Icon name="externallink" />
                  <span className="pl-1">Actividad en español</span>
                </Button>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={'section--gray'}>
        <Container>
          <Row>
            <Column size={8}>
              <h2>Career resource guides</h2>
              <p className="text-sans-serif">
                Use these resource guides to explore exciting and diverse career
                opportunities in data.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                title={dataScientist.title}
                className={dataScientist.className}
                description={dataScientist.description}
                img={dataScientist.img}
                actions={dataScientist.actions}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={innovationAnalyst.title}
                className={innovationAnalyst.className}
                description={innovationAnalyst.description}
                img={innovationAnalyst.img}
                actions={innovationAnalyst.actions}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={salesEngineer.title}
                className={salesEngineer.className}
                description={salesEngineer.description}
                img={salesEngineer.img}
                actions={salesEngineer.actions}
                linkResource
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <SurveyCallout />
      <Section className="pt-0 pb-0">
        <Subfooter
          img="footer-educators@2x.jpg"
          content={subfooterContent}
          subcontent="- U.S. Bureau of Labor Statistics"
          icon="icon-subject.svg"
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query CareerProfileResources {
    allResourcesJson(filter: { pages: { in: "career-profiles" } }) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            link
            module
            moduleName
            download
            noIcon
            asLink
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default CareerProfiles;
