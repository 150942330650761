import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Action from './Action';
import Image from '../Image';
import Icon from '../Icon';

import './styles.scss';

const Resource = ({
  ribbon,
  tophat,
  img,
  icon,
  subject,
  title,
  audience,
  duration,
  description,
  actions,
  horizontal,
  className,
  customAction,
  linkResource,
}) => {
  const getClassNames = () =>
    classNames(
      'resource',
      horizontal && 'resource--horizontal',
      linkResource && 'resource--rounded',
      className
    );

  const renderImage = () => {
    let image = (
      <div className="resource__image-wrapper">
        <Image filename={img} className="resource__image" />
        {icon && <Image filename={icon} className="resource__icon" />}
      </div>
    );

    if (actions && actions[0].linkFromImg) {
      const { metadata, label, ...actionProps } = actions[0];
      image = <Action {...actionProps} label={image} noIcon asLink />;
    }

    return (
      <>
        {tophat && <div className="resource__tophat">{tophat}</div>}
        <div className="resource__image-container">{image}</div>
      </>
    );
  };

  const renderContent = () => (
    <div className="resource__content">
      {subject && <div className="resource__subject">{subject}</div>}
      <h3 className="resource__title">{title}</h3>
      {audience && <div className="resource__audience">{audience}</div>}
      {duration && (
        <div className="resource__duration">
          <Icon name="clock" marginRight /> {duration}
        </div>
      )}
      {description && (
        <div
          className="resource__description"
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        ></div>
      )}
      {linkResource && (
        <div className="resource__actions">
          {actions.map((action, i) => (
            <Action key={i} {...action} index={i} asLink />
          ))}
          {customAction}
        </div>
      )}
      {!linkResource && actions && (
        <div className="resource__actions">
          {actions.map((action, i) => (
            <Action key={i} {...action} index={i} />
          ))}
          {customAction}
        </div>
      )}
      {customAction && <div className="resource__actions">{customAction}</div>}
    </div>
  );

  if (horizontal) {
    return (
      <Container>
        <div className={getClassNames()}>
          <Row>
            <Column size={5}>{renderImage()}</Column>
            <Column size={7}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    );
  }

  return ribbon ? (
    <div className="resource__with-ribbon">
      <div className={getClassNames()}>
        {renderImage()}
        {renderContent()}
      </div>
      <Image filename={ribbon} className="resource-ribbon" />
    </div>
  ) : (
    <div className={getClassNames()}>
      {renderImage()}
      {renderContent()}
    </div>
  );
};

Resource.defaultProps = {
  tophat: null,
  subject: null,
  audience: null,
  duration: null,
  description: null,
  actions: null,
  horizontal: false,
  className: null,
  customAction: null,
};

Resource.propTypes = {
  /** The image's file name */
  img: PropTypes.string.isRequired,
  /** The ribbon's file path */
  ribbon: PropTypes.string,
  /** The icon's file name */
  icon: PropTypes.string,
  /** Text for the tophat */
  tophat: PropTypes.string,
  /** The subject */
  subject: PropTypes.string,
  /** The title */
  title: PropTypes.string.isRequired,
  /** The audience */
  audience: PropTypes.string,
  /** The duration */
  duration: PropTypes.string,
  /** The description */
  description: PropTypes.node,
  /** The actions */
  actions: PropTypes.arrayOf(PropTypes.object),
  /** Horizontal orientation */
  horizontal: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
  /** Allow for custom Actions to be provided in the proper location.*/
  customAction: PropTypes.any,
};

export default Resource;
